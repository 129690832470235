// @flow
import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv/ngx-csv';

import { AuthService } from '../../components/auth/auth.service';
import { ImpersonationService } from '../../components/auth/impersonation.service';
import { DataService } from '../../components/data-viz/data.service';
import { LoggerService } from '../shared/logger.service';
import { OrgService } from '../../components/auth/org.service';

import { FrequencyService } from './shared/frequency.service';
import { OrganizationService } from '../organization/shared/organization.service';
import { UpdateFrequencyComponent } from '../organization/shared/update-frequency/update-frequency.component';

import { FilterSelectorComponent } from '../../components/filter-selector/filter-selector.component';

import { getCacheValue, setCacheValue, removeCacheValue } from '../../components/util.js';

@Component({
  selector: 'frequency',
  template: require('./frequency.html')
})
export class FrequencyComponent implements OnInit {
  modalRef: BsModalRef;
  ngxCsv: ngxCsv;
  currentOrg;

  static parameters = [AuthService, ImpersonationService, DataService, LoggerService, OrgService, FrequencyService, BsModalService, OrganizationService];
  constructor(authService: AuthService, impersonation: ImpersonationService, dataService: DataService, loggerService: LoggerService, orgService: OrgService, frequencyService: FrequencyService, modalService: BsModalService, organizationService: OrganizationService) {
    this.authService = authService;
    this.impersonation = impersonation;
    this.dataService = dataService;
    this.loggerService = loggerService;
    this.orgService = orgService;
    this.frequencyService = frequencyService;
    this.modalService = modalService;
    this.organizationService = organizationService;

    this.getCacheValue = getCacheValue;
    this.setCacheValue = setCacheValue;
    this.removeCacheValue = removeCacheValue;

    this.sendFrequencyChanged = false;

    this.loading = false;
    this.rateChartLoading = false;
    this.loadingFilterOptions = true;
    this.highestFrequencyChartLoading = true;

    this.currentOrg = {
      fmConfig: {
        rules: [],
        orgFmRule: {
          emailsPerTimePeriod: 1,
          timePeriodDays: 0
        }
      }
    };

    this.frequency = {
      alerts: [],
      sendBucketStats: [],
      selectedRate: null,
      rateChart: [],
      highestFrequencyChart: []
    };

    this.filters = {
      eloquaCampaigns: [],
      treatments: [],
      currentFilterSettings: {
        filterBy: 'all',
        ids: []
      }
    };
    this.loadFmReport = false;
  } //end: ctor

  ngOnInit() {
    this.init();
    this.isDemo = this.impersonation.isDemoMode();

    this.authService.getCurrentOrg().then(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
        this.authService.checkFmRules(this.currentOrg);
        this.resetOrg();
      }
    });

    this.orgSub = this.authService.currentOrgChanged.subscribe(org => {
      if(typeof org !== 'undefined') {
        this.currentOrg = org;
        this.authService.checkFmRules(this.currentOrg);
        this.resetOrg();
      }
    });

    this.isAdmin = false;
    this.authService.isAdmin().then(is => {
      this.isAdmin = is;
    });

    //Handle showing contacts for a particular email
    this.showContactInfo = false;
    this.dataSub = this.dataService.highFreqContacts.subscribe(data => {
      this.loadingContacts = true;
      this.selectedContact = null;
      this.showContactInfo = true;

      this.selectedBar = {
        range: data.sendsPerContactPerWeek,
        numberOfContacts: data.numberOfContacts
      };

      if(this.isDemo && this.filters.currentFilterSettings.filterBy === 'all') {
        this.frequencyService.getDemoHighFrequencyContacts(data.bucketStart, data.bucketEnd).then(contacts => {
          if(contacts && contacts.length > 0) {
            this.selectedBar.contacts = contacts;
            this.loadingContacts = false;
          }
        });
      }
      else {
        this.frequencyService.getHighFrequencyContacts(this.currentOrg._id, data.bucketStart, data.bucketEnd, this.filters.currentFilterSettings).then(contacts => {
          if(contacts && contacts.length > 0) {
            this.selectedBar.contacts = contacts;
            this.loadingContacts = false;
          }
        });
      }
    });
  }

  init() {
    this.legendColors = ['#CCE8F8', '#93D3ED', '#61B8E4', '#2FA1D5', '#1680BA'];
    this.rates = [
      { value: 'open', display: 'Open Rate' },
      { value: 'clickthrough', display: 'Clickthrough Rate' }
    ];
    this.frequency.selectedRate = this.rates[0];
  }

  resetOrg() {
    if(this.isDemo) {
      this.loadDemo();
      return;
    }

    //Check cache
    var frequency = this.getCacheValue('globalFrequency');
    var frequencyFilter = this.getCacheValue('globalFrequencyFilter');

    if(frequency && frequencyFilter) {
      //Found data in the cache
      this.frequency = frequency;
      this.filters = frequencyFilter;

      this.loading = false;
      this.rateChartLoading = false;
      this.loadingFilterOptions = false;
      this.highestFrequencyChartLoading = false;
    }
    else {
      this.frequency.alerts = [];
      this.buildFrequencyIntelligenceReport();
      this.frequencyService.getFilterOptions(this.currentOrg._id).then(filterOptions => {
        this.filters.eloquaCampaigns = filterOptions.eloquaCampaigns;
        this.filters.treatments = filterOptions.treatments;
        this.filters.personas = filterOptions.personas;
        this.loadingFilterOptions = false;
      });
    }
  }

  ngOnDestroy() {
    if(this.orgSub) this.orgSub.unsubscribe();
    if(this.dataSub) this.dataSub.unsubscribe();

    //Cache report data
    if(this.frequency && !this.loading && this.filters && !this.loadingFilterOptions && !this.isDemo) {
      this.setCacheValue('globalFrequency', this.frequency);
      this.setCacheValue('globalFrequencyFilter', this.filters);
    }
  }

  openFilterSelector() {
    const initialState = {
      eloquaCampaigns: this.filters.eloquaCampaigns,
      treatments: this.filters.treatments,
      personas: this.filters.personas,
      currentFilter: this.filters.currentFilterSettings,
      report: 'globalSto'
    };

    this.modalRef = this.modalService.show(FilterSelectorComponent, { initialState });

    var sub = this.modalService.onHidden.subscribe(() => {
      if(this.modalRef.content.okClicked) {
        this.filters.currentFilterSettings = this.modalRef.content.response;
        this.loading = true;
        this.highestFrequencyChartLoading = true;

        // Build report with filters
        this.buildFrequencyIntelligenceReport();
      }
      if(sub) sub.unsubscribe();
    });
  }

  clearFilter() {
    this.loading = true;
    this.filters.currentFilterSettings = {
      filterBy: 'all',
      ids: []
    };

    this.filters.eloquaCampaigns.forEach(campaign => {
      campaign.selected = false;
    });

    this.filters.treatments.forEach(treatment => {
      treatment.selected = false;
    });

    this.filters.personas.forEach(persona => {
      persona.selected = false;
    });

    // Build report unfiltered
    if(this.isDemo) {
      this.loadDemo();
    }
    else {
      this.buildFrequencyIntelligenceReport();
    }
  }

  getSelectedFilterName() {
    const selectedId = this.filters.currentFilterSettings.ids[0];
    let selectedFilter;

    switch (this.filters.currentFilterSettings.filterBy) {
      case 'personas':
        selectedFilter = this.filters.personas.find(persona => persona.personaId === selectedId);
        break;
      case 'treatments':
        selectedFilter = this.filters.treatments.find(treatment => treatment.treatment_id === selectedId);
        break;
      case 'eloquaCampaigns':
        selectedFilter = this.filters.eloquaCampaigns.find(campaign => campaign.campaign_id === selectedId);
        break;
      default:
        selectedFilter = null;
    }

    return selectedFilter ? selectedFilter.name : '';
  }

  buildFrequencyIntelligenceReport() {
    // Clear current selection and alerts
    this.selectedBar = {};
    this.selectedContact = null;
    this.frequency.alerts = [];
    this.showContactInfo = false;

    this.frequencyService.buildFrequencyIntelligenceReport(this.currentOrg._id, this.filters.currentFilterSettings, this.frequency.selectedRate).then(
      response => {
        if(response) {
          this.frequency.sendBucketStats = response.sendBucketStats;
          this.frequency.rateChart = response.rateChart;
          this.loading = false;
          this.rateChartLoading = false;

          // Build highest frequency sends report
          this.frequencyService.buildHighestFrequencySendsReport(response.sendBucketStats).then(highestFrequencyChartData => {
            this.frequency.highestFrequencyChart = highestFrequencyChartData;

            // Show bot warning if there are contacts with more than 20 sends per week
            if(highestFrequencyChartData.showBotWarning && this.frequency.alerts.length === 0) {
              this.frequency.alerts.push({
                msg: 'Motiva AI has detected possible bot abuse against your organization. You should investigate the contacts below for possible abusive behavior.',
                type: 'warning'
              });
            }
            this.highestFrequencyChartLoading = false;
          });
        }
      },
      error => {
        console.log('Error getting frequency intelligence report', error);
      }
    );
  }

  //Open dialog to update Org's FM settings
  updateFrequency() {
    const initialState = { organization: this.currentOrg };
    this.modalService.show(UpdateFrequencyComponent, { initialState, class: 'modal-lg' });
  }

  selectedRateChanged(rate) {
    this.rateChartLoading = true;
    this.frequency.selectedRate = rate;

    if(!this.isDemo) {
      this.buildFrequencyIntelligenceReport();
    }
    else {
      this.loadDemo();
    }
  }

  selectContact(selectedContact) {
    this.selectedContact = selectedContact;
    this.emailsLoading = true;

    // Highlight selected contact
    this.selectedBar.contacts.forEach(contact => {
      contact.isSelected = false;
    });
    selectedContact.isSelected = true;

    // Get email info
    if(!this.isDemo) {
      this.frequencyService.getHighFrequencyEmailsSent(this.currentOrg._id, selectedContact.eloquacontactid, this.filters.currentFilterSettings).then(
        emails => {
          selectedContact.emailsSent = emails;

          // Add email asset urls
          var url = this.authService.getEloquaInstanceUrl();
          if(url) {
            selectedContact.emailsSent.forEach(email => {
              email.eloquaAssetUrl = `${url}/Main.aspx#emails&id=${email.treatment_id}`;
            });
          }

          this.emailsLoading = false;
        },
        error => {
          console.log('Error getting high frequency emails sent', error);
          this.emailsLoading = false;
        }
      );
    }
    else {
      this.frequencyService.getDemoHighFrequencyEmailsSent(this.currentOrg._id, selectedContact.eloquacontactid, this.filters.currentFilterSettings).then(
        emails => {
          selectedContact.emailsSent = emails;

          // Add email asset urls
          var url = this.authService.getEloquaInstanceUrl();
          if(url) {
            selectedContact.emailsSent.forEach(email => {
              email.eloquaAssetUrl = `${url}/Main.aspx#emails&id=${email.treatment_id}`;
            });
          }

          this.emailsLoading = false;
        },
        error => {
          console.log('Error getting Demo high frequency emails sent', error);
          this.emailsLoading = false;
        }
      );
    }
  }

  onSelectFmReportTab() {
    setTimeout(() => {
      this.loadFmReport = true;  
    }, 100);
  }

  exportContacts(contactsToExport) {
    var filename = 'High Frequency Contacts.csv';
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      noDownload: false,
      headers: ['Contact Id']
    };

    var date = new Date().toLocaleDateString('en-US');
    // Set filename
    if(contactsToExport == 'all') {
      filename = `High_Frequency_Contacts_${date}`;
    }
    else {
      filename = `Contacts_with_${this.selectedBar.range}_average_sends_per_week_${date}`;
    }

    this.frequencyService
      .getHighFrequencyExportData(this.selectedBar, contactsToExport, this.currentOrg._id, this.filters.currentFilterSettings, this.frequency.highestFrequencyChart)
      .then(exportData => {
        new ngxCsv(exportData, filename, options);
      });
  }

  loadDemo() {
    // Clear current selection and alerts
    this.selectedBar = {};
    this.selectedContact = null;
    this.frequency.alerts = [];
    this.showContactInfo = false;

    this.frequencyService.buildDemoFrequencyIntelligenceReport(this.frequency.selectedRate).then(response => {
      if(response) {
        this.frequency.sendBucketStats = response.sendBucketStats;
        this.frequency.rateChart = response.rateChart;
        this.loading = false;
        this.rateChartLoading = false;
        this.loadingFilterOptions = false;

        // Build highest frequency sends report
        this.frequencyService.buildHighestFrequencySendsReport(response.sendBucketStats).then(highestFrequencyChartData => {
          this.frequency.highestFrequencyChart = highestFrequencyChartData;

          // Show bot warning if there are contacts with more than 20 sends per week
          if(highestFrequencyChartData.showBotWarning && this.frequency.alerts.length === 0) {
            this.frequency.alerts.push({
              msg: 'Motiva AI has detected possible bot abuse against your organization. You should investigate the contacts below for possible abusive behavior.',
              type: 'warning'
            });
          }
          this.highestFrequencyChartLoading = false;
        });
      }
    });
  }
}

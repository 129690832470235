// @flow
import { Component, NgZone, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { AuthService } from '../../../components/auth/auth.service';
import { ImpersonationService } from '../../../components/auth/impersonation.service';
import { LoggerService } from '../../shared/logger.service';
import { WhoService } from '../shared/who.service';

import { FilterSelectorComponent } from '../../../components/filter-selector/filter-selector.component';
import { ContactAttributeSelectorComponent } from '../shared/contact-attribute-selector/contact-attribute-selector.component';

import { getCacheValue, setCacheValue, removeCacheValue } from '../../../components/util.js';

import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';

@Component({
  selector: 'who',
  template: require('./global-who.html')
})
export class GlobalWhoComponent implements OnInit {
  modalRef: BsModalRef;

  static parameters = [AuthService, LoggerService, WhoService, BsModalService, NgZone, ImpersonationService];
  constructor(authService: AuthService, loggerService: LoggerService, whoService: WhoService, modalService: BsModalService, zone: NgZone,
    impersonation: ImpersonationService) {
    this.authService = authService;
    this.loggerService = loggerService;
    this.whoService = whoService;
    this.modalService = modalService;
    this.zone = zone;
    this.impersonation = impersonation;

    this.getCacheValue = getCacheValue;
    this.setCacheValue = setCacheValue;
    this.removeCacheValue = removeCacheValue;

    this.alerts = [];
    this.error = false;

    this.loadingWhoReport = false;
    this.loadingTopAttributes = true;
    this.loadingPerformanceDetails = true;
    this.loadingFilterOptions = true;

    this.whoReportLocal = {
      config: [],
      featureSets: {
        headers: [],
        data: [],
        relevantFeatures: [],
        relevantFeaturesMap: {},
        totalCountMap: {},
        featureCounts: {},
        highestCount: 0
      },
      keys: [],
      topFeaturesToggle: 'opens',
      population: {},
      orgContactFieldsLocal: [],
      treatmentDetails: {},
      historicalActivityCountsLocal: {
        numMonths: 0,
        startMonth: '',
        endMonth: ''
      },
      showMotivaPercent: true
    };

    this.filters = {
      eloquaCampaigns: [],
      treatments: [],
      currentFilterSettings: {
        filterBy: 'all',
        ids: []
      }
    };
  }

  ngOnInit() {
    this.isDemo = this.impersonation.isDemoMode();

    this.authService.getCurrentUser().then(user => {
      if(user._id !== '') {
        this.currentUser = user;
        this.orgId = this.currentUser.organizationId;
        this.startBuildingWhoReport();
      }
    });

    this.userSub = this.authService.currentUserChanged.subscribe(user => {
      if(user._id !== '') {
        this.currentUser = user;
        this.orgId = this.currentUser.organizationId;
        this.startBuildingWhoReport();
      }
    });

    this.authService.getCurrentOrg().then(org => {
      this.currentOrg = org;
    });

    this.isAdmin = false;
    this.authService.isAdmin().then(is => {
      this.isAdmin = is;
    });
  }

  ngOnDestroy() {
    if(this.userSub) this.userSub.unsubscribe();

    //Cache report data
    if(this.whoReports && this.whoReportLocal && !this.loadingWhoReport && !this.loadingTopAttributes && this.filters && !this.loadingFilterOptions && !this.isDemo) {
      this.setCacheValue('globalWho', this.whoReports);
      this.setCacheValue('globalWhoLocal', this.whoReportLocal);
      this.setCacheValue('globalWhoFilter', this.filters);
    }
  }

  startBuildingWhoReport() {
    if(this.isDemo) {
      this.loadDemo();
      return;
    }

    if(this.loadingWhoReport) return; //debounce
    this.loadingWhoReport = true;

    //Check cache
    var whoReports = this.getCacheValue('globalWho');
    var whoReportLocal = this.getCacheValue('globalWhoLocal');
    var whoReportFilter = this.getCacheValue('globalWhoFilter');

    if(whoReports && whoReportLocal && whoReportFilter) {
      this.whoReports = whoReports;
      this.whoReportLocal = whoReportLocal;
      this.filters = whoReportFilter;

      this.loadingWhoReport = false;
      this.loadingTopAttributes = false;
      this.loadingPerformanceDetails = false;
      this.loadingFilterOptions = false;
    }
    else {
      //Get months of historical activity
      this.whoService.getHistoricalActivityMonths(this.orgId).then(historicalActivityMonths => {
        this.whoReportLocal.historicalActivityCountsLocal.numMonths = historicalActivityMonths.length;

        if(historicalActivityMonths && historicalActivityMonths.length > 0) {
          var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

          this.whoReportLocal.historicalActivityCountsLocal.startMonth = `${months[historicalActivityMonths[0].month - 1]} ${historicalActivityMonths[0].year}`;

          if(historicalActivityMonths.length > 1) {
            this.whoReportLocal.historicalActivityCountsLocal.endMonth = `${months[historicalActivityMonths[historicalActivityMonths.length - 1].month - 1]} ${
              historicalActivityMonths[historicalActivityMonths.length - 1].year
            }`;
          }
        }
      });

      this.whoService
        .population()
        .toPromise()
        .then(
          population => {
            this.whoReportLocal.population = population;

            // Only build report if there is contact data processed by Motiva
            if(this.whoReportLocal.population.populationSize > 0) {
              this.loadingWhoReport = false;
              //Check if percentage of contacts Motiva has is incorrect (>100%)
              if(this.whoReportLocal.population.eloquaInstancePopulationSize <= 0 || this.whoReportLocal.population.populationSize / this.whoReportLocal.population.eloquaInstancePopulationSize > 1) {
                this.loggerService.logMessage('Data Health: Percentage of Motiva contacts greater than 100%', 'error');
                this.whoReportLocal.showMotivaPercent = false;
              }

              this.whoService.getContactAttributes(this.whoReportLocal, this.whoReportLocal.population).then(response => {
                this.whoReportLocal.orgContactFieldsLocal = cloneDeep(response.orgContactFields);
                this.orgSettings = response.orgSettings;
                this.buildWhoReport();
              });
            }
            else {
              this.loadingWhoReport = false;
            }
          },
          error => {
            console.log('Error getting population information', error);
            this.loadingWhoReport = false;
            this.error = true;
          }
        );
    }
  } //end: startBuildingWhoReport()

  buildWhoReport() {
    this.alerts = [];
    // Get filter options
    this.whoService.getFilterOptions(this.filters, this.orgId).then(
      filterOptions => {
        this.loadingFilterOptions = false;
        this.filters.eloquaCampaigns = filterOptions.eloquaCampaigns;
        filterOptions.treatments.forEach(treatment => {
          this.filters.treatments.push({
            treatment_id: treatment.treatment_id,
            name: treatment.name,
            subject: treatment.subject,
            updatedAt: treatment.email_asset_updated_at
          });
        });

        //Sort treatments by updatedAt
        this.filters.treatments.sort(function(a, b) {
          if(a.updatedAt < b.updatedAt) return -1;
          if(a.updatedAt > b.updatedAt) return -1;
          return 0;
        });

        // Get email names and subjects
        this.filters.treatments.forEach(treatment => {
          this.whoReportLocal.treatmentDetails[treatment.treatment_id.toString()] = treatment;
        });

        // Build who report
        this.whoService.buildWhoReport(this.orgId, this.whoReportLocal, this.whoReportLocal.orgContactFieldsLocal, this.filters.currentFilterSettings, this.whoReportLocal.treatmentDetails).then(
          response => {
            this.zone.run(() => {
              this.whoReports = response.reports;

              if(response.allTreatmentsNotShown) {
                this.alerts.push({
                  type: 'info',
                  msg: `The selected campaigns contain more than ${this.whoService.WHO_REPORT_MAX_TREATMENTS}
                emails. The reports below display only the most recent ${this.whoService.WHO_REPORT_MAX_TREATMENTS} emails from those campaigns.`
                });
              }
              this.loadingPerformanceDetails = false;
            });
          },
          err => {
            console.log('Error building who report', err);
            this.loadingPerformanceDetails = false;
            this.error = true;
          }
        );
      },
      error => {
        console.log('Error: retrieving who filter options.', error);
      }
    );

    // Get top performing features
    this.whoService.getTopPerformingFeatures(this.orgId, this.whoReportLocal, this.whoReportLocal.orgContactFieldsLocal).then(
      () => {
        this.loadingTopAttributes = false;
      },
      err => {
        console.log('Error: getting top attributes', err);
        this.loadingTopAttributes = false;
      }
    );
  } //end: buildWhoReport()

  topFeaturesChanged() {
    this.loadingTopAttributes = true;
    // Get top performing features
    if(this.isDemo) {
      this.whoService.getDemoTopPerformingFeatures(this.whoReportLocal).then(() => {
        this.loadingTopAttributes = false;
      },
      err => {
        console.log('error getting demo top attributes', err);
      });
    }
    else {
      this.whoService.getTopPerformingFeatures(this.orgId, this.whoReportLocal, this.whoReportLocal.orgContactFieldsLocal).then(
        () => {
          this.loadingTopAttributes = false;
        },
        err => {
          console.log('Error getting top attributes', err);
          this.loadingTopAttributes = false;
        }
      );
    }
  }

  openFilterSelector() {
    const initialState = {
      eloquaCampaigns: this.filters.eloquaCampaigns,
      treatments: this.filters.treatments,
      currentFilter: this.filters.currentFilterSettings,
      report: 'globalWho',
      maxTreatments: this.whoService.WHO_REPORT_MAX_TREATMENTS
    };

    this.modalRef = this.modalService.show(FilterSelectorComponent, { initialState });

    var sub = this.modalService.onHidden.subscribe(() => {
      if(this.modalRef.content.okClicked) {
        this.filters.currentFilterSettings = this.modalRef.content.response;
        this.loadingPerformanceDetails = true;

        this.whoReportLocal.config = [];

        // rebuild who report with filters
        this.whoService.buildWhoReport(this.orgId, this.whoReportLocal, this.whoReportLocal.orgContactFieldsLocal, this.filters.currentFilterSettings, this.whoReportLocal.treatmentDetails).then(
          response => {
            this.alerts = [];
            this.whoReports = response.reports;

            if(response.allTreatmentsNotShown) {
              this.alerts.push({
                type: 'info',
                msg: `The selected campaigns contain more than ${this.whoService.WHO_REPORT_MAX_TREATMENTS}
                emails. The reports below display only the most recent ${this.whoService.WHO_REPORT_MAX_TREATMENTS} emails from those campaigns.`
              });
            }

            this.loadingPerformanceDetails = false;
          },
          err => {
            console.log('Error building who report after selecting filter', err);
            this.loadingPerformanceDetails = false;
          }
        );
      }
      if(sub) sub.unsubscribe();
    });
  }

  clearFilter() {
    this.alerts = [];
    this.loadingPerformanceDetails = true;
    this.filters.currentFilterSettings = {
      filterBy: 'all',
      ids: []
    };

    this.filters.eloquaCampaigns.forEach(campaign => {
      campaign.selected = false;
    });

    this.filters.treatments.forEach(treatment => {
      treatment.selected = false;
    });

    // If there are more than 20 emails, only show the most recent
    if(this.filters.treatments.length > this.whoService.WHO_REPORT_MAX_TREATMENTS) {
      this.filters.currentFilterSettings.filterBy = 'treatments';

      this.filters.treatments.forEach(treatment => {
        if(this.filters.currentFilterSettings.ids.length < this.whoService.WHO_REPORT_MAX_TREATMENTS) {
          this.filters.currentFilterSettings.ids.push(treatment.treatment_id);
        }
      });

      this.alerts.push({
        type: 'info',
        msg: `The selected campaigns contain more than ${this.whoService.WHO_REPORT_MAX_TREATMENTS}
        emails. The reports below display only the most recent ${this.whoService.WHO_REPORT_MAX_TREATMENTS} emails from those campaigns.`
      });
    }

    // rebuild who report without filters
    this.whoService.buildWhoReport(this.orgId, this.whoReportLocal, this.whoReportLocal.orgContactFieldsLocal, this.filters.currentFilterSettings, this.whoReportLocal.treatmentDetails).then(
      response => {
        this.whoReports = response.reports;
        this.loadingPerformanceDetails = false;
      },
      err => {
        console.log('Error building who report after clearning filter', err);
        this.loadingPerformanceDetails = false;
      }
    );
  }

  selectContactFields() {
    var initialState = {
      campaign: null,
      totalCountMap: this.whoReportLocal.featureSets.totalCountMap,
      totalNum: this.whoReportLocal.population.populationSize
    };

    this.modalRef = this.modalService.show(ContactAttributeSelectorComponent, { initialState });

    var sub = this.modalService.onHidden.subscribe(() => {
      if(this.modalRef.content.okClicked) {
        this.alerts = [];
        this.loadingPerformanceDetails = true;

        this.whoService.getContactAttributes(this.whoReportLocal, this.whoReportLocal.population).then(response => {
          this.whoReportLocal.orgContactFieldsLocal = cloneDeep(response.orgContactFields);
          this.orgSettings = response.orgSettings;
        });

        //Refresh who report with selected fields
        var selectedContactFields = this.modalRef.content.selectedContactFields;
        this.whoService.buildWhoReport(this.orgId, this.whoReportLocal, selectedContactFields, this.filters.currentFilterSettings, this.whoReportLocal.treatmentDetails).then(
          response => {
            this.whoReports = response.reports;

            if(response.allTreatmentsNotShown) {
              this.alerts.push({
                type: 'info',
                msg: `The selected campaigns contain more than ${this.whoService.WHO_REPORT_MAX_TREATMENTS}
                emails. The reports below display only the most recent ${this.whoService.WHO_REPORT_MAX_TREATMENTS} emails from those campaigns.`
              });
            }

            this.loadingPerformanceDetails = false;
          },
          err => {
            console.log('Error building who report after updating contact fields', err);
            this.loadingPerformanceDetails = false;
          }
        );
      }
      if(sub) sub.unsubscribe();
    });
  }

  loadDemo() {
    this.whoReports = [];
    this.whoReportLocal.population = {
      populationSize: 14305110,
      eloquaInstancePopulationSize: 15163420,
      contactFieldCoverageCounts: []
    };

    this.whoReportLocal.historicalActivityCountsLocal.numMonths = 12;
    this.whoReportLocal.historicalActivityCountsLocal.startMonth = moment().subtract(1, 'year');
    this.whoReportLocal.historicalActivityCountsLocal.endMonth = moment();
    this.whoReportLocal.population.updatedAt = moment();

    if(!this.whoReportLocal.orgContactFieldsLocal || this.whoReportLocal.orgContactFieldsLocal.length <= 0) {
      this.whoService.getContactAttributes(this.whoReportLocal, this.whoReportLocal.population).then(response => {
        this.whoReportLocal.orgContactFieldsLocal = cloneDeep(response.orgContactFields);
        this.orgSettings = response.orgSettings;

        //Add coverage.
        this.whoReportLocal.orgContactFieldsLocal.forEach(item => {
          var percent = (Math.floor(Math.random() * 2000) + 7000) / 10000;
          item.coverageCount = this.whoReportLocal.population.populationSize * percent;
        });

        this.whoService.buildDemoWhoReport(this.whoReportLocal, this.whoReportLocal.treatmentDetails).then(whoReportData => {
          if(whoReportData) {
            this.whoReports = whoReportData.reports;
            this.loadingPerformanceDetails = false;
            this.loadingTopAttributes = false;
            this.loadingFilterOptions = false;
            this.loadingWhoReport = false;
          }
        });
      },
      err => {
        console.log('Error getting contact attributes for demo', err);
      });
    }
    else {
      this.whoService.buildDemoWhoReport(this.whoReportLocal, this.whoReportLocal.treatmentDetails).then(whoReportData => {
        if(whoReportData) {
          this.whoReports = whoReportData.reports;
          this.loadingPerformanceDetails = false;
          this.loadingTopAttributes = false;
          this.loadingFilterOptions = false;
          this.loadingWhoReport = false;
        }
      });
    }
  } //end: loadDemo()
}

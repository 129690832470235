// @flow
import { Component, OnInit, SimpleChanges } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';

import { AuthService } from '../../../../components/auth/auth.service';
import { PersonaService } from '../persona.service';

import { ContactAttributeSelectorComponent } from '../contact-attribute-selector/contact-attribute-selector.component';
import { RemoveAttributeComponent } from './remove-attribute/remove-attribute.component.js';
import { DeletePersonaComponent } from './delete-persona/delete-persona.component.js';
import { SegmentSelectorComponent } from '../../../shared/segment-selector/segment-selector.component.js';

import { cloneDeep } from 'lodash';
import * as d3 from 'd3';

@Component({
  selector: 'persona-definition',
  template: require('./persona-definition.html')
})
export class PersonaDefinitionComponent implements OnInit {
  visibleContactFields: [];
  population: {};
  persona: {};
  isDemo: false;

  static parameters = [BsModalService, PersonaService, AuthService, HttpClient];
  constructor(modalService: BsModalService, personaService: PersonaService, authService: AuthService, http: HttpClient) {
    this.modalService = modalService;
    this.personaService = personaService;
    this.authService = authService;
    this.http = http;
  }

  ngOnInit() {
    this.alerts = [];

    this.personaLocal = {
      name: '',
      attributes: [],
      definitionType: 'segment',
      personaStats: {}
    };

    this.pageSize = 50;

    this.selectedContactFields = [];
    this.selectedKeyValueMap = {};

    this.authService.getCurrentUser().then(user => {
      this.currentUser = user;
    });

    this.authService.getCurrentOrg().then(org => {
      this.currentOrg = org;

      this.init();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'persona') {
        this.init();
      }
    }
  }

  init() {
    this.loadingAttributes = true;

    if(this.persona && this.persona.personaId) {
      this.personaLocal = cloneDeep(this.persona);

      if(this.persona.attributes && this.persona.attributes.length > 0) {
        this.selectedKeyValueMap = {};
        this.persona.attributes.forEach(attribute => {
          if(!this.selectedKeyValueMap[attribute.key]) {
            this.selectedKeyValueMap[attribute.key] = [];
          }
          this.selectedKeyValueMap[attribute.key].push(attribute.value);
        });

        this.visibleContactFields.forEach(field => {
          if(this.selectedKeyValueMap[field.key]) {
            this.selectedContactFields.push(field);
          }
        });
      }
      else if(this.persona.definitionType === 'segment' && this.persona.segmentId) {
        this.getSegment();
      }

      this.personaLocal.attributes = [];
      this.personaService.initAttributes(this.personaLocal, this.selectedContactFields, this.currentOrg._id, this.pageSize, this.selectedKeyValueMap).then(() => {
        if(this.isDemo) this.loadDemo();
        this.loadingAttributes = false;
      });
    }
    else {
      this.personaLocal = {
        name: '',
        definitionType: 'segment',
        attributes: [],
        personaStats: {
          totalDistinctSends: 0,
          totalDistinctOpens: 0,
          totalDistinctClickthroughs: 0,
          contactCount: 0,
          openRate: null,
          clickthroughRate: null,
          clickToOpenRate: null
        }
      };

      this.visibleContactFields.forEach(field => {
        if(field.isSelected) {
          this.selectedContactFields.push(field);
        }
      });

      this.personaService.initAttributes(this.personaLocal, this.selectedContactFields, this.currentOrg._id, this.pageSize).then(() => {
        if(this.isDemo) this.loadDemo();
        this.loadingAttributes = false;
      });
    }
  }

  changeDefinitionType() {
    if(this.personaLocal.definitionType === 'attributes') {
      this.personaLocal.definitionType = 'segment';
    }
    else {
      this.personaLocal.definitionType = 'attributes';
    }
  }

  openSegmentSelector() {
    var initialState = {
      orgId: this.currentOrg._id
    };

    this.modalRef = this.modalService.show(SegmentSelectorComponent, {
      initialState,
      class: 'modal-md'
    });

    var sub = this.modalService.onHidden.subscribe(() => {
      // Make sure that onHidden was called by modal
      if(this.modalRef.content.okClicked) {
        this.personaLocal.segment = this.modalRef.content.selectedSegment;
        console.log('selected segment', this.personaLocal.segment);
      }

      if(sub) sub.unsubscribe();
    });
  }

  getSegment() {
    this.http
      .get(`/eloqua-api/api/segment/${this.personaLocal.segmentId}`)
      .toPromise()
      .then(
        segment => {
          this.personaLocal.segment = segment;
        },
        error => {
          console.log('Error getting segment', error);
          // Try refreshing eloqua token and try to get segment again
          this.authService
            .verifyAuth(this.currentUser._id)
            .toPromise()
            .then(
              () => {
                // Token was refreshed successfully, so try getting segments again
                this.http
                .get(`/eloqua-api/api/segment/${this.personaLocal.segmentId}`)
                .toPromise()
                .then(
                  segment => {
                    this.personaLocal.segment = segment;
                  },
                  error => {
                    console.log('Error getting segment', error);
                  });
              },
              error => {
                console.log('Error verifying auth', error);
              });
        });
  }

  addAttribute() {
    this.currentAttributes = [];
    this.personaLocal.attributes.forEach(attribute => {
      this.currentAttributes.push(attribute.key);
    });

    var initialState = {
      campaign: null,
      totalNum: null,
      totalCountMap: null,
      persona: this.personaLocal,
      selectedContactFieldsLocal: this.selectedContactFields
    };

    this.modalRef = this.modalService.show(ContactAttributeSelectorComponent, {
      initialState,
      class: 'modal-md',
      id: 2
    });

    var sub = this.modalService.onHidden.subscribe((reason) => {
      // Make sure that onHidden was called by ContactAttributeSelector modal
      if(reason.id == 2) {
        if(this.modalRef.content.okClicked) {
          this.loadingAttributes = true;
          this.selectedContactFields = this.modalRef.content.selectedContactFields;
          var selectedKeys = [];

          this.selectedContactFields.forEach(field => {
            selectedKeys.push(field.key);
            if(this.currentAttributes.indexOf(field.key) <= -1) {
              this.currentAttributes.push(field.key);

              // Get values for attribute
              var keyValueMap = {};
              this.personaService.getAttributeValuePage(keyValueMap, this.currentOrg._id, field.key, this.pageSize, 0).then(() => {
                if(keyValueMap[field.key]) {
                  // sort values
                  this.sortValues(keyValueMap[field.key]);

                  //Add attribute wtih values to personaLocal
                  this.personaLocal.attributes.push({
                    name: field.name,
                    key: field.key,
                    values: keyValueMap[field.key],
                    valuesFiltered: cloneDeep(keyValueMap[field.key]),
                    search: {
                      text: '',
                      pageNum: 0,
                      busy: false
                    }
                  });
                }

                this.loadingAttributes = false;
              });
            }
          });
        }
        if(sub) sub.unsubscribe();
      }
    });
  }

  sortValues(values) {
    if(values) {
      values.sort((a, b) => {
        if(a.isSelected === b.isSelected) {
          if(a.value < b.value) return -1;
          else return 1;
        }
        else if(a.isSelected) return -1;
        else return 1;
      });
    }
  }

  removeAttribute(attributeIndex, attribute) {
    //Display Delete/Cancel Modal
    const initialState = {
      attribute
    };
    this.bsModalRef = this.modalService.show(RemoveAttributeComponent, {
      initialState,
      id: 3
    });
    var sub = this.bsModalRef.content.action.subscribe(doIt => {
      if(doIt) {
        this.personaLocal.attributes.splice(attributeIndex, 1);

        this.selectedContactFields.forEach((field, i) => {
          if(field.key == attribute.key) {
            this.selectedContactFields.splice(i, 1);
          }
        });
      }
      if(sub) sub.unsubscribe();
    });
  }

  toggleValue(selectedValue, attribute) {
    this.alerts = [];
    selectedValue.isSelected = !selectedValue.isSelected;

    attribute.values.forEach(val => {
      if(val.value == selectedValue.value) {
        val.isSelected = !val.isSelected;
      }
    });

    // If selected, add to selectedKeyValueMap
    if(selectedValue.isSelected) {
      if(!this.selectedKeyValueMap[attribute.key]) {
        this.selectedKeyValueMap[attribute.key] = [];
      }

      this.selectedKeyValueMap[attribute.key].push(selectedValue.value);
    }
    // if not selected, remove from selectedKeyValueMap
    else {
      var index = this.selectedKeyValueMap[attribute.key].indexOf(selectedValue.value);
      if(index >= 0) {
        this.selectedKeyValueMap[attribute.key].splice(index, 1);
      }
    }
  }

  selectAllValues(attribute) {
    this.alerts = [];
    attribute.allSelected = !attribute.allSelected;
    attribute.valuesFiltered.forEach(val => {
      val.isSelected = attribute.allSelected;
    });

    attribute.values.forEach(val => {
      val.isSelected = attribute.allSelected;

      if(attribute.allSelected) {
        // Add value to selectedKeyValueMap
        if(!this.selectedKeyValueMap[attribute.key]) {
          this.selectedKeyValueMap[attribute.key] = [];
          this.selectedKeyValueMap[attribute.key].push(val.value);
        }
        else {
          const found = this.selectedKeyValueMap && this.selectedKeyValueMap[attribute.key] ? this.selectedKeyValueMap[attribute.key].find(el => el == val.value) : false;
          if(!found) {
            this.selectedKeyValueMap[attribute.key].push(val.value);
          }
        }
      }
      else {
        this.selectedKeyValueMap[attribute.key] = [];
      }
    });
  }

  filterValues(attribute) {
    if(!attribute.search.busy) {
      if(this._timeout) {
        clearTimeout(this._timeout);
      }

      //Wait a bit before running search
      this._timeout = setTimeout(() => {
        this.runNewSearch(attribute);
      }, 1.2 * 1000);
    }
  }

  runNewSearch(attribute) {
    attribute.values = [];
    attribute.search.stop = false;
    attribute.search.pageNum = 0;
    attribute.search.text = attribute.searchString;

    // Add selected values that meet search at top
    function searchFilter(val) {
      return val.toString()
        .toLowerCase()
        .indexOf(attribute.searchString.toLowerCase()) > -1;
    }

    if(this.selectedKeyValueMap && this.selectedKeyValueMap[attribute.key]) {
      var filteredSelectedValues = this.selectedKeyValueMap[attribute.key].filter(searchFilter);

      filteredSelectedValues.forEach(value => {
        attribute.values.push({
          value: !isNaN(value) ? Number(value, 10) : value,
          displayValue: this.personaService.formatValue(value),
          isSelected: true
        });
      });
    }

    this.getValuesPage(attribute);
  }

  getNextPageForAttributeValues(attribute) {
    // Only get next page if there are more values to get
    if(attribute.values.length >= this.pageSize) {
      attribute.search.pageNum++;
      this.getValuesPage(attribute);
    }
  }

  getValuesPage(attribute) {
    var keyValueMap = [];
    this.personaService.getAttributeValuePage(
      keyValueMap,
      this.currentOrg._id,
      attribute.key,
      this.pageSize,
      attribute.search.pageNum,
      attribute.search.text
    ).then(() => {
      keyValueMap[attribute.key].forEach(newVal => {
        const found = this.selectedKeyValueMap && this.selectedKeyValueMap[attribute.key] ? this.selectedKeyValueMap[attribute.key].find(el => el == newVal.value) : false;
        if(!found) {
          attribute.values.push(newVal);
        }
      });

      attribute.valuesFiltered = cloneDeep(attribute.values);
      attribute.search.busy = false;
    }, err => {
      console.log('Error getting values', err);
      attribute.search.busy = false;
    });
  }

  deletePersona() {
    this.alerts = [];
    //Display Delete/Cancel Modal
    this.bsModalRef = this.modalService.show(DeletePersonaComponent, {
      id: 3
    });
    var sub = this.bsModalRef.content.action.subscribe(doIt => {
      if(doIt && !this.isDemo) {
        this.deletedPersona = true;
        this.personaService.deletePersona(this.currentOrg._id, this.personaLocal.personaId).then(() => {
          this.modalService.hide(1);
        },
        err => {
          var alertMessage = 'There was an error deleting this persona.';
          this.alerts.push({ msg: alertMessage, type: 'danger' });
          console.log('Error deleting persona', err);
        });
      }
      if(sub) sub.unsubscribe();
    });
  }

  ok() {
    this.alerts = [];

    if(this.personaLocal.definitionType === 'attributes') {
      var atLeastOneValueSelected = false;
      this.personaLocal.attributes.forEach(attribute => {
        attribute.values.forEach(val => {
          if(val.isSelected) atLeastOneValueSelected = true;
        });
      });

      // For now, hide the modal right away and save/update in the background to
      // avoid a false error showing when the endpoint times out.
      if(atLeastOneValueSelected) {
        this.okClicked = true;
        this.modalService.hide(1);
      }
      else {
        this.alerts.push({msg: 'At least <b>1</b> attribute value must be selected to save Persona', type: 'warning'});
      }
    }
    else if(this.personaLocal.segment && this.personaLocal.segment.id) {
      this.okClicked = true;
      this.modalService.hide(1);
    }
    else {
      this.alerts.push({msg: 'An Eloqua segment must be selected to save Persona', type: 'warning'});
    }
  }

  cancel() {
    this.okClicked = false;
    this.modalService.hide(1);
  }

  loadDemo() {
    this.personaLocal.attributes.forEach(item => {
      if(item.key === 'AnnualRevenue') {
        item.values.forEach(e => {
          e.displayValue = `$${d3.format(',')(e.displayValue)}`;
        });
        item.valuesFiltered = cloneDeep(item.values);
      }
      else if(item.key === 'City') {
        item.values = [
          { name: 'Calgary', displayValue: 'Calgary', isSelected: false },
          { name: 'Edmonton', displayValue: 'Edmonton', isSelected: false },
          { name: 'Montreal', displayValue: 'Montreal', isSelected: false },
          { name: 'Quebec City', displayValue: 'Quebec City', isSelected: false },
          { name: 'Toronto', displayValue: 'Toronto', isSelected: false },
          { name: 'Vancouver', displayValue: 'Vancouver', isSelected: false },
          { name: 'Winnipeg', displayValue: 'Winnipeg', isSelected: false }
        ];
        item.valuesFiltered = cloneDeep(item.values);
      }
      else if(item.key === 'Company') {
        item.values = [
          { name: 'Air Canada', displayValue: 'Air Canada', isSelected: false },
          { name: 'Blackberry', displayValue: 'Blackberry', isSelected: false },
          { name: 'Lululemon Athletica', displayValue: 'Lululemon Athletica', isSelected: false },
          { name: 'McCain Foods', displayValue: 'McCain Foods', isSelected: false },
          { name: 'Royal Bank of Canada', displayValue: 'Royal Bank of Canada', isSelected: false },
          { name: 'Shaw Communications', displayValue: 'Shaw Communications', isSelected: false },
          { name: 'Tim Hortons', displayValue: 'Tim Hortons', isSelected: false }
        ];
        item.valuesFiltered = cloneDeep(item.values);
      }
      else if(item.key === 'CompanySize') {
        item.values = [
          { name: '1-500', displayValue: '1-500', isSelected: false },
          { name: '501-1,000', displayValue: '501-1,000', isSelected: false },
          { name: '1,001-5,000', displayValue: '1,001-5,000', isSelected: false },
          { name: '5,001-10,000', displayValue: '5,001-10,000', isSelected: false },
          { name: '10,001-50,000', displayValue: '10,001-50,000', isSelected: false }
        ];
        item.valuesFiltered = cloneDeep(item.values);
      }
      else if(item.key === 'Industry') {
        item.values = [
          { name: 'Education', displayValue: 'Education', isSelected: false },
          { name: 'Finance', displayValue: 'Finance', isSelected: false },
          { name: 'Government', displayValue: 'Government', isSelected: false },
          { name: 'Health', displayValue: 'Health', isSelected: false },
          { name: 'Manufacturing', displayValue: 'Manufacturing', isSelected: false },
          { name: 'Retail', displayValue: 'Retail', isSelected: false }
        ];
        item.valuesFiltered = cloneDeep(item.values);
      }
    });
  }
}

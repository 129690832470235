import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { cloneDeep } from 'lodash';

@Component({
  selector: 'filter-selector-modal',
  template: require('./filter-selector.html')
})
export class FilterSelectorComponent {
  eloquaCampaigns: any[] = [];
  treatments: any[] = [];
  personas: any[] = [];
  report: string;
  maxTreatments: number;
  currentFilter: {};

  constructor(bsModalRef: BsModalRef) {
    this.bsModalRef = bsModalRef;

    this.loading = true;
    this.title = '';
    this.searchText = '';
    this.alerts = [];

    this.filterLocal = {
      options: [
        {
          display: 'Eloqua Campaign',
          value: 'eloquaCampaigns'
        }
      ],
      selected: {}
    };
    this.selectedTreatmentCount = 0;
    this.selectAllChecked = false;
  }

  ngOnInit() {
    // Set title
    switch (this.report) {
    case 'globalFunnel':
      this.title = 'Funnel Data';
      break;
    case 'frequencyIntelligence':
      this.title = 'Frequency Intelligence Data';
      break;
    case 'globalWho':
      this.title = 'Who Responded Data';
      break;
    case 'globalSto':
      this.title = 'Send Time Data';
      break;
    }

    // Add emails to dropdown
    if(this.treatments && this.treatments.length > 0) {
      this.filterLocal.options.push({
        display: 'Email',
        value: 'treatments'
      });
    }

    // Add personas to dropdown if they exist
    if(this.personas && this.personas.length > 0) {
      this.filterLocal.options.push({
        display: 'Persona',
        value: 'personas'
      });
    }

    // Set current selection
    if(this.currentFilter.filterBy == 'eloquaCampaigns') {
      this.eloquaCampaigns.forEach(campaign => {
        campaign.selected = false;

        if((this.report == 'globalWho' || !campaign.transientIds) && this.currentFilter.ids.indexOf(campaign.campaign_id) > -1) {
          campaign.selected = true;
        }
        else if(this.report !== 'globalWho' && campaign.transientIds) {
          campaign.transientIds.forEach(transientId => {
            if(this.currentFilter.ids.indexOf(transientId) > -1) {
              campaign.selected = true;
            }
          });
        }
      });

      // Sort with selected first
      this.eloquaCampaigns.sort((a, b) => {
        if(a.selected === b.selected) return 0;
        if(a.selected) return -1;
        else return 1;
      });
    }
    else if(this.currentFilter.filterBy == 'treatments') {
      this.treatments.forEach(treatment => {
        treatment.selected = false;
        if(this.currentFilter.ids.indexOf(treatment.treatment_id) > -1) {
          treatment.selected = true;
          this.selectedTreatmentCount++;
        }
      });

      // Sort with selected first
      this.treatments.sort((a, b) => {
        if(a.selected === b.selected) return 0;
        if(a.selected) return -1;
        else return 1;
      });

      this.eloquaCampaigns.forEach(campaign => {
        campaign.selected = false;
      });
    }
    else if(this.currentFilter.filterBy == 'personas') {
      this.personas.forEach(persona => {
        persona.selected = false;
        if(this.currentFilter.ids.indexOf(persona.personaId) > -1) {
          persona.selected = true;
        }
      });

      // Sort with selected first
      this.personas.sort((a, b) => {
        if(a.selected === b.selected) return 0;
        if(a.selected) return -1;
        else return 1;
      });

      this.eloquaCampaigns.forEach(campaign => {
        campaign.selected = false;
      });

      this.treatments.forEach(treatment => {
        treatment.selected = false;
      });
    }

    this.filteredTreatments = cloneDeep(this.treatments);
    this.filteredEloquaCampaigns = cloneDeep(this.eloquaCampaigns);
    this.filteredPersonas = cloneDeep(this.personas);

    if(this.currentFilter.filterBy == 'treatments') {
      const treatmentsIndex = this.filterLocal.options.findIndex(option => option.value === 'treatments');
      this.filterLocal.selected = this.filterLocal.options[treatmentsIndex];
    }
    else if(this.currentFilter.filterBy == 'personas') {
      const personaIndex = this.filterLocal.options.findIndex(option => option.value === 'personas');
      this.filterLocal.selected = this.filterLocal.options[personaIndex];
    }
    else {
      this.filterLocal.selected = this.filterLocal.options[0];
    }

    this.loading = false;
  }

  toggleCheckbox(selected, type) {
    this.alerts = [];
    selected.selected = !selected.selected;

    // toggle selection in full list (not just filtered list)
    if(this.filterLocal.selected.value == 'eloquaCampaigns') {
      this.eloquaCampaigns.forEach(campaign => {
        if(campaign.campaign_id == selected.campaign_id) {
          campaign.selected = selected.selected;
        }
      });
    }
    else if(this.filterLocal.selected.value == 'treatments') {
      this.treatments.forEach(treatment => {
        if(treatment.treatment_id == selected.treatment_id) {
          treatment.selected = selected.selected;
        }
      });
    }
    else if(this.filterLocal.selected.value == 'personas') {
      this.personas.forEach(persona => {
        if(persona.personaId == selected.personaId) {
          persona.selected = selected.selected;
        }
      });
    }

    // For who report, only allow maxTreatments to be selected at a time
    if(type == 'email' && this.report == 'globalWho') {
      if(this.selectedTreatmentCount >= this.maxTreatments && selected.selected) {
        selected.selected = false;

        this.alerts.push({ type: 'warning', msg: `There are already ${this.maxTreatments} emails selected. Uncheck a different email to select this one.` });
      }
      else if(selected.selected) {
        this.selectedTreatmentCount++;
      }
      else {
        this.selectedTreatmentCount--;
      }
    }
  }

  selectAll() {
    this.selectAllChecked = !this.selectAllChecked;
    console.log('this.selectAllChecked', this.selectAllChecked);
    if(this.filterLocal.selected.value == 'eloquaCampaigns') {
      this.filteredEloquaCampaigns.forEach(campaign => {
        campaign.selected = this.selectAllChecked;
      });

      this.eloquaCampaigns.forEach(campaign => {
        if (this.filteredEloquaCampaigns.some(fc => fc.campaign_id === campaign.campaign_id)) {
          campaign.selected = this.selectAllChecked;
        }
      });
    }
    else if(this.filterLocal.selected.value == 'treatments') {
      this.filteredTreatments.forEach(treatment => {
        treatment.selected = this.selectAllChecked;
      });
  
      this.treatments.forEach(treatment => {
        if (this.filteredTreatments.some(ft => ft.treatment_id === treatment.treatment_id)) {
          treatment.selected = this.selectAllChecked;
        }
      });
    }
    else if(this.filterLocal.selected.value == 'personas') {
      this.filteredPersonas.forEach(persona => {
        persona.selected = this.selectAllChecked;
      });

      this.personas.forEach(persona => {
        if (this.filteredPersonas.some(fp => fp.personaId === persona.personaId)) {
          persona.selected = this.selectAllChecked;
        }
      });
    }
  }

  searchFilter() {
    // clear selectAll when filter changes
    this.selectAllChecked = false;
    var searchString = this.searchText.toLowerCase();

    if(searchString && this.filterLocal.selected.value == 'eloquaCampaigns') {
      function searchFilter(campaign) {
        return campaign.name && campaign.name.toLowerCase().indexOf(searchString) > -1 || campaign.campaign_id && campaign.campaign_id.toString().indexOf(searchString) > -1;
      }

      this.filteredEloquaCampaigns = this.eloquaCampaigns.filter(searchFilter);
    }
    else if(searchString && this.filterLocal.selected.value == 'treatments') {
      function searchFilter(treatment) {
        return (
          treatment.name && treatment.name.toLowerCase().indexOf(searchString) > -1
          || treatment.subject && treatment.subject.toLowerCase().indexOf(searchString) > -1
          || treatment.treatment_id && treatment.treatment_id.toString().indexOf(searchString) > -1
        );
      }
      this.filteredTreatments = this.treatments.filter(searchFilter);
    }
    else if(searchString && this.filterLocal.selected.value == 'personas') {
      function searchFilter(persona) {
        return persona.name && persona.name.toLowerCase().indexOf(searchString) > -1;
      }
      this.filteredPersonas = this.personas.filter(searchFilter);
    }
  }

  changeFilterBy(selected) {
    this.filterLocal.selected = selected;
    this.searchText = '';
    this.selectAllChecked = false;
  }

  ok() {
    this.okClicked = true;
    var response = {
      ids: [],
      filterBy: this.filterLocal.selected.value,
      numberSelected: 0
    };

    if(this.filterLocal.selected.value == 'eloquaCampaigns') {
      this.eloquaCampaigns.forEach(campaign => {
        if(campaign.selected) {
          response.numberSelected++;
          if(this.report == 'globalWho' || !campaign.transientIds) {
            response.ids.push(campaign.campaign_id);
          }
          else {
            campaign.transientIds.forEach(transientId => {
              response.ids.push(transientId);
            });

            response.ids.push(campaign.campaign_id);
          }
        }
      });
    }
    else if(this.filterLocal.selected.value == 'treatments') {
      this.treatments.forEach(treatment => {
        if(treatment.selected) {
          response.numberSelected++;
          response.ids.push(treatment.treatment_id);
        }
      });
    }
    else if(this.filterLocal.selected.value == 'personas') {
      this.personas.forEach(persona => {
        if(persona.selected) {
          response.numberSelected++;
          response.ids.push(persona.personaId);
        }
      });
    }

    this.response = response;
    this.bsModalRef.hide();
  }

  cancel() {
    this.okClicked = false;
    this.bsModalRef.hide();
  }
}

// @flow
import { Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Population } from './who';

@Injectable({
  providedIn: 'root'
})
export class WhoService {
  MAX_FEATURE_SETS = 5;
  WHO_REPORT_MAX_TREATMENTS = 20;

  static parameters = [HttpClient, DecimalPipe];
  constructor(http: HttpClient, decimalPipe: DecimalPipe) {
    this.http = http;
    this.decimalPipe = decimalPipe;
  }

  population(): Observable<Population> {
    return this.http.get('/core-api/api/organization/population');
  }

  getOrgSettings() {
    const promise = new Promise((resolve, reject) => {
      this.http
        .get('/core-api/api/organization/settings')
        .toPromise()
        .then(
          settings => {
            resolve(settings);
          },
          //Error Handling...
          error => {
            console.error('There was an error getting org settings', error);
            reject(error);
          }
        );
    });

    return promise;
  }

  updateOrgSettings(orgSettings) {
    const promise = new Promise((resolve, reject) => {
      this.http
        .put('/core-api/api/organization/settings', orgSettings)
        .toPromise()
        .then(
          settings => {
            resolve(settings);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  getHistoricalActivityMonths(orgId) {
    console.log('getHistoricalActivityMonths orgId', orgId);
    var params = { orgId };
    const promise = new Promise((resolve, reject) => {
      this.http
        .get('/api/olap/historicalActivityMonths', { params })
        .toPromise()
        .then(
          historicalActivityMonths => {
            resolve(historicalActivityMonths);
          },
          error => {
            console.error('Error getting historicalActivityMonths', error);
            reject(error);
          }
        );
    });

    return promise;
  }

  getContactAttributes(whoReportLocal, population) {
    const promise = new Promise((resolve, reject) => {
      this.getOrgSettings().then(
        orgSettings => {
          var orgContactFields = orgSettings.visibleContactAttributes;

          //Build total counts map
          whoReportLocal.featureSets.totalCountMap = {};

          //The population is only passed when building the Global Who report.
          if(population) {
            if(population.contactFieldCoverageCounts.length > 0) {
              population.contactFieldCoverageCounts.forEach(row => {
                whoReportLocal.featureSets.totalCountMap[row.key] = row.count;
              });
            }
          }

          //Add counts to orgContactFields
          if(orgContactFields && orgContactFields.length > 0) {
            orgContactFields.forEach(field => {
              field.coverageCount = whoReportLocal.featureSets.totalCountMap[field.key] ? whoReportLocal.featureSets.totalCountMap[field.key] : 0;
            });
          }

          orgContactFields.sort(function(a, b) {
            if(a.key < b.key) {
              return -1;
            }
            if(a.key > b.key) {
              return 1;
            }
            return 0;
          });

          resolve({
            orgContactFields,
            orgSettings
          });
        },
        error => {
          console.log('Error getting org settings', error);
          reject(error);
        }
      );
    });

    return promise;
  }

  getFilterOptions(filters, orgId) {
    var response = {
      eloquaCampaigns: [],
      treatments: []
    };

    const promise = new Promise((resolve, reject) => {
      var params = {
        orgId
      };
      // Get Eloqua Campaigns with Who data
      this.http
        .get('/api/olap/whoReportEloquaCampaigns', { params })
        .toPromise()
        .then(
          eloquaCampaigns => {
            response.eloquaCampaigns = eloquaCampaigns;

            // Get treatments with Who data
            this.http
              .get('/api/olap/whoReportTreatments', { params })
              .toPromise()
              .then(
                treatments => {
                  response.treatments = treatments;
                  resolve(response);
                },
                err => {
                  reject(err);
                }
              );
          },
          error => {
            console.log('Error: retrieving Eloqua who report data.', error);
            reject(error);
          }
        );
    });

    return promise;
  }

  //Return a list of contact attributes / CDO's that actually exist in the data.
  findRelevantFeatures(orgId, filterSettings) {
    const promise = new Promise((resolve, reject) => {
      var params = {
        orgId,
        filterBy: filterSettings ? filterSettings.filterBy : '',
        ids: filterSettings ? filterSettings.ids : []
      };

      this.http
        .get('/api/olap/whoFeatures', {
          params
        })
        .toPromise()
        .then(
          relevantFeatures => {
            var featureKeys = [];
            relevantFeatures.forEach(feature => {
              featureKeys.push(feature.key);
            });
            resolve(featureKeys);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  getTopPerformingFeatures(orgId, whoReportLocal, selectedContactFields, filterSettings) {
    const promise = new Promise((resolve, reject) => {
      var data = [];
      var params = {
        orgId,
        activity: whoReportLocal.topFeaturesToggle,
        filterBy: filterSettings ? filterSettings.filterBy : '',
        ids: filterSettings ? filterSettings.ids : [],
        pageSize: this.MAX_FEATURE_SETS * 4,
        offset: 0
      };

      whoReportLocal.featureSets.highestCount = 0;

      //Only show non-hidden keys
      var checkKeys = null;
      if(selectedContactFields && selectedContactFields.length > 0) {
        checkKeys = {};
        selectedContactFields.forEach(visibleContactAttribute => {
          checkKeys[visibleContactAttribute.key] = true;
        });

        this.http
          .get('/api/olap/whoTotal', {
            params
          })
          .toPromise()
          .then(
            topFeatures => {
              if(topFeatures && topFeatures.length > 0) {
                for(var i = 0; i < topFeatures.length; i++) {
                  if(topFeatures[i]) {
                    var key = topFeatures[i].key;
                    var name = key.replace(/([A-Z])/g, ' $1');
                    name = name.charAt(0).toUpperCase() + name.slice(1);

                    if(checkKeys && checkKeys[key] || !checkKeys) {
                      data.push({
                        key: topFeatures[i].key,
                        field: name,
                        value: topFeatures[i].value,
                        opens: topFeatures[i].opens,
                        clicks: topFeatures[i].clicks
                      });
                    }

                    if(data.length >= this.MAX_FEATURE_SETS) break;
                  }
                }

                // If there are less than 5 top attributes, get the next page
                if(data.length < this.MAX_FEATURE_SETS) {
                  this.getTotalData(params, data, checkKeys).then(() => {
                    if(data && data.length > 0 && Number(data[0].opens) > Number(whoReportLocal.featureSets.highestCount)) {
                      whoReportLocal.featureSets.highestCount = Number(data[0].opens);
                    }

                    whoReportLocal.featureSets.data = data;
                    resolve();
                  });
                }
                else {
                  if(Number(data[0].opens) > Number(whoReportLocal.featureSets.highestCount)) {
                    whoReportLocal.featureSets.highestCount = Number(data[0].opens);
                  }

                  whoReportLocal.featureSets.data = data;
                  resolve();
                }
              }
              else {
                whoReportLocal.featureSets.data = [];
                resolve();
              }
            },
            error => {
              reject(error);
            }
          );
      }
      else {
        whoReportLocal.featureSets.data = [];
        resolve();
      }
    });

    return promise;
  }

  getTotalData(params, data, checkKeys) {
    const promise = new Promise((resolve, reject) => {
      this.getTotalDataPage(params, data, checkKeys, resolve, reject);
    });
    return promise;
  }

  getTotalDataPage(params, data, checkKeys, resolve, reject) {
    params.offset = params.offset + params.pageSize;
    this.http
      .get('/api/olap/whoTotal', {
        params
      })
      .toPromise()
      .then(
        totalData => {
          if(totalData && totalData.length > 0) {
            for(var i = 0; i < totalData.length; i++) {
              if(totalData[i]) {
                var key = totalData[i].key;
                var name = key.replace(/([A-Z])/g, ' $1');
                name = name.charAt(0).toUpperCase() + name.slice(1);

                if(checkKeys && (checkKeys[key] || !checkKeys) && data.length < this.MAX_FEATURE_SETS) {
                  data.push({
                    key: totalData[i].key,
                    field: name,
                    value: totalData[i].value,
                    opens: totalData[i].opens,
                    clicks: totalData[i].clicks
                  });
                }
              }
            }

            // If there are less than 5 top attributes, get the next page
            if(data.length < this.MAX_FEATURE_SETS) {
              this.getTotalDataPage(params, data, checkKeys, resolve, reject);
            }
            else {
              console.log('Resolve total data page');
              resolve();
            }
          }
          else {
            resolve();
          }
        },
        error => {
          reject(error);
          console.log('Error getting who report total', error);
        }
      );
  }

  getWhoReportData(orgId, whoReportLocal, filterSettings, treatmentDetails) {
    var whoReportData = {};
    var attributeValueOpens = {};
    var maxOpens = 0;
    var response = {
      reports: []
    };

    const promise = new Promise((resolve, reject) => {
      var params = {
        orgId,
        keys: whoReportLocal.keys,
        filterBy: filterSettings ? filterSettings.filterBy : '',
        ids: filterSettings ? filterSettings.ids : [],
        maxNumberOfValues: 20
      };

      this.http
        .get('/api/olap/who', { params })
        .toPromise()
        .then(
          whoData => {
            this.processWhoData(whoData, whoReportData, whoReportLocal, attributeValueOpens, maxOpens, treatmentDetails, response).then(() => {
              resolve(response);
            });
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  processWhoData(whoData, whoReportData, whoReportLocal, attributeValueOpens, maxOpens, treatmentDetails, response) {
    const promise = new Promise(resolve => {
      whoData.openAndClickData.forEach(d => {
        var theVal = d.value;

        if(isFinite(d.value)) {
          theVal = parseInt(d.value);
        }

        if(!whoReportData[d.treatment_id]) {
          whoReportData[d.treatment_id] = {};
        }

        if(!whoReportData[d.treatment_id].hasOwnProperty(d.key)) {
          whoReportData[d.treatment_id][d.key] = {};
        }

        if(!whoReportData[d.treatment_id][d.key].hasOwnProperty(theVal)) {
          whoReportData[d.treatment_id][d.key][theVal] = {};
        }

        if(!whoReportData[d.treatment_id][d.key][theVal].hasOwnProperty('clicks')) {
          whoReportData[d.treatment_id][d.key][theVal].clicks = d.clicks;
        }

        if(!whoReportData[d.treatment_id][d.key][theVal].hasOwnProperty('opens')) {
          whoReportData[d.treatment_id][d.key][theVal].opens = d.opens;
        }

        // open rate for this value
        if(!attributeValueOpens[d.key]) {
          attributeValueOpens[d.key] = {};
        }

        if(!attributeValueOpens[d.key][theVal]) {
          attributeValueOpens[d.key][theVal] = 0;
        }

        attributeValueOpens[d.key][theVal] += d.opens;
      });

      //Only show the who report, if we find some open or click data.
      if(whoData.openAndClickData.length > 0) {
        whoReportLocal.show = true;
      }

      //Create sends map
      var sendCounts = {};
      whoData.sendData.forEach(treatment => {
        sendCounts[treatment.treatment_id] = treatment.total_sent;
      });

      if(Object.keys(whoReportData).length > this.WHO_REPORT_MAX_TREATMENTS) {
        response.allTreatmentsNotShown = true;
      }

      if(whoReportLocal.show) {
        this.processAllAttributeReports(whoReportLocal, whoReportData, treatmentDetails, sendCounts, maxOpens, whoData).then(reports => {
          // Get max opens
          reports.forEach(report => {
            if(report.maxOpens > maxOpens) {
              maxOpens = report.maxOpens;
            }
          });

          reports.forEach(report => {
            report.data.maxOpens = maxOpens;
            response.reports.push(report.data);
          });

          response.reports.sort(function(a, b) {
            if(a.key < b.key) {
              return -1;
            }
            if(a.key > b.key) {
              return 1;
            }
            return 0;
          });

          resolve(response);
        });
      }
      else {
        resolve(response);
      }
    });
    return promise;
  } // end: processWhoData()

  processAllAttributeReports(whoReportLocal, whoReportData, treatmentDetails, sendCounts, maxOpens, whoData) {
    var promises = [];
    var keys = Object.keys(whoReportData);

    if(keys.length > this.WHO_REPORT_MAX_TREATMENTS && treatmentDetails) {
      //sort keys by updated at
      keys.sort(function(a, b) {
        if(treatmentDetails[a] && treatmentDetails[b]) {
          if(treatmentDetails[a].updatedAt > treatmentDetails[b].updatedAt) {
            return -1;
          }
          if(treatmentDetails[a].updatedAt < treatmentDetails[b].updatedAt) {
            return 1;
          }
        }
        return 0;
      });
    }

    whoReportLocal.config.forEach(whoReport => {
      promises.push(this.processAttributeReportData(whoReport, keys, treatmentDetails, sendCounts, maxOpens, whoData, whoReportData));
    });

    return Promise.all(promises);
  }

  processAttributeReportData(whoReport, keys, treatmentDetails, sendCounts, maxOpens, whoData, whoReportData) {
    const promise = new Promise(resolve => {
      whoReport.show = true;

      var data = {
        key: whoReport.key,
        name: whoReport.name,
        show: whoReport.show,
        allValuesDisplayed: whoData.allValuesDisplayed[whoReport.key],
        valueOrder: [],
        results: []
      };

      keys.forEach((key, i) => {
        // if more than 20 treatments, only show the most recent 20
        if(whoReportData[key][whoReport.key] && i < this.WHO_REPORT_MAX_TREATMENTS) {
          var values = Object.keys(whoReportData[key][whoReport.key]);
          var obj = {
            treatment_id: key,
            name: treatmentDetails[key] ? treatmentDetails[key].name : '',
            subject: treatmentDetails[key] ? treatmentDetails[key].subject : '',
            totalSent: sendCounts[key] ? sendCounts[key] : 0,
            updatedAt: treatmentDetails[key] ? treatmentDetails[key].updatedAt : '',
            results: []
          };

          values.forEach(value => {
            if(data.valueOrder.indexOf(value) < 0) {
              data.valueOrder.push(value);
            }

            var result = {
              value,
              opens: whoReportData[key][whoReport.key][value].opens,
              clicks: whoReportData[key][whoReport.key][value].clicks
            };

            //Find the max opens across the board.
            if(result.opens > maxOpens) maxOpens = result.opens;
            obj.results.push(result);
          });

          data.results.push(obj);
        }
      });

      data.valueOrder.sort(function(a, b) {
        return a - b;
      });

      if(data.results.length <= 0) {
        data.show = false;
      }
      resolve({ data, maxOpens });
    });

    return promise;
  } //end: processAttributeReportData()

  buildWhoReport(orgId, whoReportLocal, selectedContactFields, filterSettings, treatmentDetails) {
    whoReportLocal.config = [];

    const promise = new Promise((resolve, reject) => {
      if(selectedContactFields) {
        selectedContactFields.forEach(contactField => {
          if(contactField.isSelected) {
            whoReportLocal.config.push({
              name: contactField.name,
              key: contactField.key,
              show: true
            });

            whoReportLocal.keys.push(contactField.key);
          }
        });
      }

      this.getWhoReportData(orgId, whoReportLocal, filterSettings, treatmentDetails).then(
        whoData => {
          resolve(whoData);
        },
        err => {
          console.log('Error getting who data', err);
          reject(err);
        }
      );
    });

    return promise;
  }

  buildDemoWhoReport(whoReportLocal, treatmentDetails) {
    var whoReportData = {};
    var attributeValueOpens = {};
    var maxOpens = 0;
    var response = {
      reports: []
    };

    const promise = new Promise((resolve, reject) => {
      whoReportLocal.config = [
        {
          name: 'Company Size',
          key: 'CompanySize',
          show: true
        },
        {
          name: 'Annual Revenue',
          key: 'AnnualRevenue',
          show: true
        },
        {
          name: 'Industry',
          key: 'Industry',
          show: true
        },
        {
          name: 'Job Role',
          key: 'JobRole',
          show: true
        }
      ];

      this.http
        .get('/assets/demo/global/who.json')
        .toPromise()
        .then(
          whoData => {
            this.processWhoData(whoData, whoReportData, whoReportLocal, attributeValueOpens, maxOpens, treatmentDetails, response).then(() => {
              // Get top performing features
              this.getDemoTopPerformingFeatures(whoReportLocal).then(() => {
                resolve(response);
              });
            });
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  getDemoTopPerformingFeatures(whoReportLocal) {
    var data = [];
    const promise = new Promise((resolve, reject) => {
      this.http
        .get(`/assets/demo/global/whoTotal-${whoReportLocal.topFeaturesToggle}.json`)
        .toPromise()
        .then(
          topFeatures => {
            if(topFeatures && topFeatures.length > 0) {
              for(var i = 0; i < topFeatures.length; i++) {
                if(topFeatures[i]) {
                  var key = topFeatures[i].key;
                  var name = key.replace(/([A-Z])/g, ' $1');
                  name = name.charAt(0).toUpperCase() + name.slice(1);

                  data.push({
                    key: topFeatures[i].key,
                    field: name,
                    value: topFeatures[i].value,
                    opens: topFeatures[i].opens,
                    clicks: topFeatures[i].clicks
                  });

                  if(data.length >= this.MAX_FEATURE_SETS) break;
                }
              }
              if(Number(data[0].opens) > Number(whoReportLocal.featureSets.highestCount)) {
                whoReportLocal.featureSets.highestCount = Number(data[0].opens);
              }

              whoReportLocal.featureSets.data = data;
              resolve();
            }
            else {
              whoReportLocal.featureSets.data = [];
              resolve();
            }
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }
}

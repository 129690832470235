// @flow
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FrequencyService {
  static parameters = [HttpClient];

  HIGHEST_BUCKET_TO_SHOW = 11;

  constructor(http: HttpClient) {
    this.http = http;
  }

  getFilterOptions(orgId) {
    var response = {
      eloquaCampaigns: [],
      treatments: [],
      personas: []
    };

    const promise = new Promise((resolve, reject) => {
      var params = {
        orgId
      };
      // Get Eloqua Campaigns with frequency data
      this.http
        .get('/api/olap/frequencyIntelligenceEloquaCampaigns', { params })
        .toPromise()
        .then(eloquaCampaigns => {
          var eloquaCampaignIds = [];
          var eloquaCampaignMap = {};

          eloquaCampaigns.forEach(eloquaCampaign => {
            if(eloquaCampaignIds.indexOf(eloquaCampaign.campaign_id) > -1) {
              if(eloquaCampaign.transient_id) {
                eloquaCampaignMap[eloquaCampaign.campaign_id].transientIds.push(eloquaCampaign.transient_id);
              }
            }
            else {
              eloquaCampaignIds.push(eloquaCampaign.campaign_id);
              eloquaCampaignMap[eloquaCampaign.campaign_id] = {
                transientIds: [],
                name: eloquaCampaign.name
              };

              if(eloquaCampaign.transient_id) {
                eloquaCampaignMap[eloquaCampaign.campaign_id].transientIds.push(eloquaCampaign.transient_id);
              }
            }
          });

          eloquaCampaignIds.forEach(id => {
            response.eloquaCampaigns.push({
              campaign_id: id,
              transientIds: eloquaCampaignMap[id].transientIds,
              name: eloquaCampaignMap[id].name
            });
          });

          // Get treatments with frequency data
          this.http
            .get('/api/olap/frequencyIntelligenceTreatments', { params })
            .toPromise()
            .then(
              treatments => {
                response.treatments = treatments;

                // Get personas list
                this.http.get(`/trans-am-api/api/personas/organization/${orgId}`, { params }).toPromise().then(personas => {
                  // Filter out attribute personas. TODO: remove this once attribute personas are fully removed.
                  response.personas = personas.filter(persona => persona.definitionType !== 'attributes');
                  console.log('got personas for filter. personas', response.personas);
                  resolve(response);
                });
              },
              error => {
                reject(error);
              }
            );
        });
    });

    return promise;
  } //end: getFilterOptions

  buildFrequencyIntelligenceReport(orgId, filterSettings, selectedRate) {
    const promise = new Promise((resolve, reject) => {
      var params = {
        orgId,
        filterBy: filterSettings.filterBy,
        ids: filterSettings.ids
      };

      this.http
        .get('/api/olap/frequencyIntelligence', { params })
        .toPromise()
        .then(
          frequencyIntelligenceData => {
            this.processFrequencyIntelligenceData(frequencyIntelligenceData, selectedRate).then((response) => {
              resolve(response);
            });
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  } //end: buildFrequencyIntelligenceReport

  processFrequencyIntelligenceData(frequencyIntelligenceData, selectedRate) {
    var sendBucketStats = [];

    const promise = new Promise((resolve) => {
      if(frequencyIntelligenceData.length > 0) {
        frequencyIntelligenceData.forEach(bucket => {
          var stats = {
            sendsPerContactPerWeek: bucket.average_sends_per_week,
            totalSends: bucket.total_sends,
            population: bucket.population,
            clickthrough: {
              count: bucket.total_clickthroughs,
              rate: null,
              credibleIntervalMin: null,
              credibleIntervalMax: null
            },
            open: {
              count: bucket.total_opens,
              rate: null,
              credibleIntervalMin: null,
              credibleIntervalMax: null
            },
            unsubscribe: {
              count: bucket.total_unsubscribes,
              rate: null,
              credibleIntervalMin: null,
              credibleIntervalMax: null
            }
          };

          //Add rate, credibleIntervalMin and credibleIntervalMax
          var rates = ['clickthrough', 'open', 'unsubscribe'];
          rates.forEach(r => {
            var rate = stats[r].count / stats.totalSends;
            if(stats.totalSends > 30 && stats.totalSends * rate > 10 && stats.totalSends * rate * (1 - rate) > 10) {
              stats[r].rate = rate;
              // standard error
              var rateStdErr = Math.sqrt(stats[r].rate * (1 - stats[r].rate) / stats.totalSends);

              //margin of error (1/2 of the confidence interval)
              var rateErrMargin = rateStdErr * 1.96;

              stats[r].credibleIntervalMin = stats[r].rate - rateErrMargin;
              stats[r].credibleIntervalMax = stats[r].rate + rateErrMargin;

              if(stats[r].credibleIntervalMin < 0) stats[r].credibleIntervalMin = 0;
              if(stats[r].credibleIntervalMax > 1) stats[r].credibleIntervalMax = 1;
            }
          });

          sendBucketStats.push(stats);
        });

        //If unsubscribes are all 0, hide line
        var showUnsubscribes = false;
        sendBucketStats.forEach(bucket => {
          if(bucket.unsubscribe.rate && bucket.unsubscribe.rate > 0) showUnsubscribes = true;
        });

        // If there are several buckets with 0% rate, only show the first
        var chartRateData = [];
        var firstZeroBucket = false;
        sendBucketStats.forEach(bucket => {
          if(bucket.sendsPerContactPerWeek < this.HIGHEST_BUCKET_TO_SHOW) {
            if(bucket[selectedRate.value].rate === 0 && !firstZeroBucket) {
              firstZeroBucket = true;
              chartRateData.push(bucket);
            }
            else {
              firstZeroBucket = false;
              chartRateData.push(bucket);
            }
          }
        });
      }

      var response = {
        rateChart: {
          selectedRate: selectedRate.value,
          rateData: chartRateData,
          colors: ['#1680BA', '#2FA1D5', '#61B8E4', '#93D3ED', '#CCE8F8'],
          showUnsubscribes
        },
        sendBucketStats
      };
      resolve(response);
    });

    return promise;
  }

  buildHighestFrequencySendsReport(sendBucketStats) {
    var highestFrequencyChart = {
      showBotWarning: false
    };
    var highFrequencySends = [];

    const promise = new Promise(resolve => {
      sendBucketStats.forEach(bucket => {
        // if average_sends_per_week > 10, add to highestFrequencySends
        if(bucket.sendsPerContactPerWeek >= 10) {
          highFrequencySends.push({
            sendsPerContactPerWeek: bucket.sendsPerContactPerWeek,
            numberOfContacts: bucket.population
          });

          // If there are contacts with more than 20 sends per week, display bot warning
          if(bucket.sendsPerContactPerWeek >= 20) {
            highestFrequencyChart.showBotWarning = true;
          }
        }
      });

      //Sort high frequency sends into buckets
      highestFrequencyChart.sendsPerWeekBuckets = this.bucketSort(highFrequencySends);
      resolve(highestFrequencyChart);
    });

    return promise;
  }

  getHighFrequencyContacts(orgId, bucketStart, bucketEnd, filterSettings) {
    const promise = new Promise((resolve, reject) => {
      var params = {
        orgId,
        bucketStart,
        bucketEnd,
        filterBy: filterSettings.filterBy,
        ids: filterSettings.ids
      };

      this.http
        .get('/api/olap/highFrequencyContacts', { params })
        .toPromise()
        .then(
          highFrequencyContacts => {
            resolve(highFrequencyContacts);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  getHighFrequencyEmailsSent(orgId, contactId, filterSettings) {
    const promise = new Promise((resolve, reject) => {
      var params = {
        orgId,
        contactId,
        filterBy: filterSettings.filterBy,
        ids: filterSettings.ids
      };

      this.http
        .get('/api/olap/highFrequencyEmailsSent', { params })
        .toPromise()
        .then(
          emails => {
            resolve(emails);
          },
          error => {
            reject(error);
          }
        );
    });
    return promise;
  }

  bucketSort(array) {
    if(array.length === 0) {
      return array;
    }

    if(array.length <= 5) {
      var buckets = [];
      array.forEach(bucket => {
        buckets.push({
          sendsPerContactPerWeek: bucket.sendsPerContactPerWeek,
          numberOfContacts: bucket.numberOfContacts,
          bucketStart: bucket.sendsPerContactPerWeek,
          bucketEnd: bucket.sendsPerContactPerWeek
        });
      });

      return buckets;
    }

    var minValue = array[0].sendsPerContactPerWeek;
    var maxValue = array[array.length - 1].sendsPerContactPerWeek;
    var numBuckets = 4;
    var bucketSize = Math.floor((maxValue - minValue) / numBuckets); // Round bucket sizes to nearest 10

    // Initialize buckets
    var bucketCount = Math.floor((maxValue - minValue) / bucketSize) + 1;
    var allBuckets = new Array(bucketCount);

    for(var i = 0; i < allBuckets.length; i++) {
      allBuckets[i] = {
        sendsPerContactPerWeek: '',
        numberOfContacts: 0,
        bucketStart: minValue + i * bucketSize,
        bucketEnd: minValue + (i + 1) * bucketSize,
        sendsPerWeekIncluded: []
      };
    }

    // Push values to buckets
    array.forEach(bucket => {
      var currentVal = bucket.sendsPerContactPerWeek;
      allBuckets[Math.floor((currentVal - minValue) / bucketSize)].numberOfContacts += bucket.numberOfContacts;
      allBuckets[Math.floor((currentVal - minValue) / bucketSize)].sendsPerWeekIncluded.push(currentVal);
    });

    // Get display value
    var finalBuckets = [];
    allBuckets.forEach(bucket => {
      if(bucket.sendsPerWeekIncluded.length == 1) {
        bucket.sendsPerContactPerWeek = bucket.sendsPerWeekIncluded[0];
        finalBuckets.push(bucket);
      }
      else if(bucket.sendsPerWeekIncluded.length > 0) {
        bucket.sendsPerContactPerWeek = `${bucket.sendsPerWeekIncluded[0]} - ${bucket.sendsPerWeekIncluded[bucket.sendsPerWeekIncluded.length - 1]}`;

        finalBuckets.push(bucket);
      }
    });

    return finalBuckets;
  } //end: bucketSort

  getHighFrequencyExportData(selectedBar, contactsToExport, orgId, currentFilter, highestFrequencyChart) {
    var exportData = [];

    const promise = new Promise((resolve, reject) => {
      if(contactsToExport === 'selected') {
        selectedBar.contacts.forEach(contact => {
          exportData.push({
            id: contact.eloquacontactid
          });
        });

        resolve(exportData);
      }
      else {
        var start = highestFrequencyChart.sendsPerWeekBuckets[0].bucketStart;
        var end = highestFrequencyChart.sendsPerWeekBuckets[highestFrequencyChart.sendsPerWeekBuckets.length - 1].bucketEnd;
        this.getHighFrequencyContacts(orgId, start, end, currentFilter).then(
          contacts => {
            if(contacts && contacts.length > 0) {
              contacts.forEach(contact => {
                exportData.push({
                  id: contact.eloquacontactid
                });
              });
            }

            resolve(exportData);
          },
          error => {
            reject(error);
          }
        );
      }
    });

    return promise;
  }

  buildDemoFrequencyIntelligenceReport(selectedRate) {
    const promise = new Promise((resolve, reject) => {
      this.http
        .get('/assets/demo/global/frequency.json')
        .toPromise()
        .then(
          frequencyIntelligenceData => {
            this.processFrequencyIntelligenceData(frequencyIntelligenceData, selectedRate).then(response => {
              resolve(response);
            });
          },
          error => {
            reject(error);
          }
        );
    });
    return promise;
  }

  getDemoHighFrequencyContacts(bucketStart, bucketEnd) {
    const promise = new Promise((resolve, reject) => {
      this.http
        .get(`/assets/demo/global/highFrequencyContacts/${bucketStart}-${bucketEnd}-sends-per-week.json`)
        .toPromise()
        .then(
          contacts => {
            resolve(contacts);
          },
          error => {
            reject(error);
          }
        );
    });
    return promise;
  }

  getDemoHighFrequencyEmailsSent(orgId, contactId, filterSettings) {
    const promise = new Promise((resolve, reject) => {
      this.http
        .get(`/assets/demo/global/highFrequencyEmails/org-${orgId}-emails.json`)
        .toPromise()
        .then(
          emails => {
            resolve(emails);
          },
          error => {
            reject(error);
          }
        );
    });
    return promise;
  }
}
